// core
import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { PrismicRichText } from "@prismicio/react";
import Accordion from "react-bootstrap/Accordion"
import parse from 'html-react-parser'

// components
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import CommonPageBanner from '../components/CommonPageBanner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

const FAQ = ({ data }) => {
    const pageContent = data.prismicFaqPage;
    const pageData = data.prismicFaqPage.data;
    const [openItems, setOpenItems] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImg, setModalImg] = useState('');
    const [activeKey, setActiveKey] = useState(null);

    const { lang, type, url } = pageContent;
    const alternateLanguages = pageContent.alternate_languages || [];
    const activeDoc = {
      lang,
      type,
      url,
      alternateLanguages,
    }

    const handleAccordionToggle = (index, key) => {
        const newOpenItems = [];
        newOpenItems[index] = !newOpenItems[index];
        setActiveKey(index === activeKey ? null : index);
        setOpenItems(newOpenItems);
    };

    const handleOpenModal = (event, image) => {
        event.preventDefault()
        setModalImg(image)
        setModalIsOpen(true)
    };

    const handleCloseModal = () => {
        setModalIsOpen(false)
    }

    return(
        <Layout currentPage={"faq"} activeDocMeta={activeDoc}>
            <Seo title={pageData.meta_title?.text} description={pageData.meta_description?.text} image={pageData.social_card?.url} />

            <section id="faq" className="inside-content faq">
                <CommonPageBanner title={pageData.banner_title.text} backgroundUrl={pageData.banner_background.url} subtitle={pageData.banner_subtitle.text} description={pageData.banner_description.html} />

                <div className="container py-5">
                    <h1 className="mb-5">
                        <PrismicRichText field={pageData.page_title.richText}/>
                    </h1>

                    <Accordion
                        defaultActiveKey={["0"]}
                        activeKey={activeKey}
                        key={"0"}
                        onSelect={handleAccordionToggle}
                        onClick={(e) => {
                            // data layer
                        }}
                    >
                    {
                        pageData.accordion.map((element, index) => {
                            return(
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>
                                        <FontAwesomeIcon icon={openItems[index] ? faMinus : faPlus} aria-hidden="false" size="lg" />
                                        <span>{parse(element.title.richText[0].text)}</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        {element.content_no_cols.richText[0].type === "preformatted" ?
                                            <p>{parse(element.content_no_cols.richText[0].text)}</p>:
                                            parse(element.content_no_cols.html)
                                        }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                    </Accordion>
                </div>
            </section>
        </Layout>
    )
}


export const query = graphql`
  query FaqPageQuery($uid: String, $id: String, $lang: String) {
    prismicFaqPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        banner_background {
          alt
          url
        }
        banner_description {
          text
        }
        banner_subtitle {
          text
        }
        banner_title {
          text
        }
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_title {
          richText
        }
        accordion {
          title {
            richText
            html
          }
          content_no_cols {
            richText
            html
          }
          content_col_1 {
            richText
            html
          }
          content_col_2 {
            richText
            html
          }
          content_col_3 {
            richText
            html
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(FAQ);
